.invite-screen{
	&--wrapper {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		// background: fade-in($color: transparent, $amount: 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 200 !important;
	}
	&--loader{
		height: 50px;
	}
	&--message{
		padding-top: var(--base-padding);
		font-size: var(--font-size-5);
		max-width: 500px;
		text-align: center;
	}
}