.session-body .calender-btn i {
	color: var(--color-text-40-light);
}

.icon_blue {
	color: var(--color-text-60-light);
}
.disable-in-archived {
	pointer-events: none;
}

.session-body .dot {
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border-radius: 8px;
	&__pending {
		background: var(--color-primary-light);
	}
	&__processing {
		background: var(--color-accent-light);
	}
	&__completed {
		background: var(--color-green);
	}
}
.session-status {
	display: flex;
	align-items: center;
	text-transform: capitalize;
	flex-wrap: wrap;
	.status__clip {
		font-size: var(--font-size-1);
		display: flex;
		align-items: center;
		color: var(--color-bg-100-light);
		line-height: 12px;
		margin-left: -3px;
		margin-bottom: 2px;
		&.statusbg {
			&__pending {
				.inner__status {
					background: var(--color-text-50-light);
					margin-left: -1px;
					margin-right: -1px;
				}
				svg > path {
					fill: var(--color-text-50-light);
				}
			}
			&__processing {
				.inner__status {
					background: var(--color-accent-light);
				}
				svg > path {
					fill: var(--color-accent-light);
				}
			}
			&__failed,
			&__rejected {
				.inner__status {
					background: var(--color-rejected);
				}
				svg > path {
					fill: var(--color-rejected);
				}
			}
			&__completed {
				.inner__status {
					background: var(--color-green);
				}
				svg > path {
					fill: var(--color-green);
				}
			}
		}

		&:first-child {
			margin-left: 0px;
			.leftImg {
				display: none;
			}
		}

		svg {
			height: 100%;
		}
	}
}

.archive-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	text-align: center !important;
}

.extra {
	padding: 8px 8px 8px 10px;
	color: var(--color-text-70-light);
}

.archive-btn :hover {
	border-radius: 50%;
	background-color: hsla(0, 0%, 0%, 0.06);
}

.session-body .Review {
	font-size: var(--font-size-2);
	font-weight: 500;
	line-height: var(--line-height-2);
	color: var(--color-primary-light);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.session-body .rdrDayPassive .rdrDayNumber {
	display: none;
}
[data-rbd-drag-handle-context-id='1'],
[data-rbd-drag-handle-context-id='3'] {
	cursor: grabbing !important;
}

.session-body .btn__iconFront {
	border: 1px solid var(--color-input-border-light);
	color: var(--color-text-40-light);
	padding: calc(var(--base-padding) * 3 / 4) calc(var(--base-padding) * 1.25);
	background-color: var(--color-bg-100-light);
	display: flex;
	flex-direction: row-reverse;
}
.session-body .table-responsive-actions__btns {
	gap: calc(var(--base-margin) * 0.5);
	position: relative;
	display: flex;
	flex-flow: wrap;
	flex-direction: row;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
	color: var(--color-text-90-light);
}
.rdrStartEdge,
.rdrEndEdge {
	background: #3c65d6;
}
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
	color: var(--color-bg-100-light) !important;
}

.warning {
	display: flex;
	gap: calc(var(--base-margin) * 0.5);
	align-items: flex-start;
	border: 1px solid var(--color-orange);
	padding: var(--base-padding);
	border-radius: calc(var(--base-border-radius) * 0.5);
	background: rgba(239, 182, 67, 0.06);
	i {
		color: var(--color-orange);
		font-size: var(--font-size-4);
	}
}

.select-component {
	display: flex;
	flex-direction: column;
	gap: calc(var(--base-margin) * 0.5);
	.select-label {
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-input-border-focus-dark);
		text-transform: capitalize;
		font-weight: 500;
	}
}
/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.kycDetails--header--sticky {
	position: sticky;
	top: 0;
	z-index: 2;
	background: white;
	padding-top: 14px;
}

.document-percentage {
	.wrapper {
		max-width: 400px;
		display: flex;
		align-items: center;
	}

	.container {
		height: 15px;
		background: rgb(224, 224, 222);
		border-radius: 50px;
		width: 100%;
		padding: 0;
	}

	.barCompleted {
		height: 15px;
		width: 80%;
		background: var(--color-primary-dark-blue);
		transition: width 1s ease-in-out 0s;
		border-radius: inherit;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.label {
		color: var(--color-text-100-light);
		font-weight: 500;
		font-size: 12px;
		margin-left: 8px;
		display: none;
	}

	&__confidence {
		width: 10%;
	}
}

.label-percentage {
	color: var(--color-text-70-light);
	font-weight: 500;
	font-size: 12px;
	width: 0px;
	margin-left: 8px;
}

.progress-value {
	margin-left: 8px;
	width: 25%;
}

.cancel-btn {
	display: flex;
	align-items: center;
	color: var(--color-bg-copy);
}
.model-header {
	background: var(--color-bg-100-light);
}

.modal-body {
	background: var(--color-bg-90-light);
	border-radius: 0 0 8px 8px;
	padding: calc(var(--base-padding) * 2);
	height: auto;
}
h2 {
	font-weight: 700;
	font-size: var(--font-size-4);
	line-height: var(--line-height-5);
	color: var(--color-header-label-dark);
	margin: 0;
}
.button--color-green {
	background: var(--color-green);
	color: var(--color-bg-100-light);
	font-size: var(--font-size-3);
}
.button--reject {
	color: var(--color-rejected);
	border: 2px solid var(--color-rejected);
	font-size: var(--font-size-3);
	padding: calc(var(--base-padding) * 0.85) calc(var(--base-padding) * 1.5);
}
.kycDetails-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 52px;
	padding: 8px 12px;
	min-width: 560px;
	border-radius: 4px 4px 0px 0px;
	border-bottom: 1px solid var(--color-border-light-100, #dfe3ec);
	button {
		margin: 0 calc(var(--base-margin) / 2);
	}
}
.action-btn {
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-weight: 400;
	&__pending {
		width: 130px;
		display: flex;
		justify-content: center;
		padding: 8px;
		border: 1px solid var(--color-border-90-light);
		color: var(--color-text-60-light);
		border-radius: 8px;
	}
	&__rejected {
		width: 130px;
		display: flex;
		justify-content: center;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid var(--color-rejected);
		color: var(--color-rejected);
	}
	&__approved {
		width: 130px;
		display: flex;
		justify-content: center;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid var(--color-green);
		color: var(--color-green);
	}
}
.person-photo {
	width: 100%;
	object-fit: contain;
	max-width: 300px;
	max-height: 300px;
	&:hover {
		cursor: pointer;
	}
}
.person-placeholder {
	width: 200px;
	height: 200px;
}
.kycDetail-left {
	min-width: 420px;
	&__innerLeft {
		padding: 12px 0px;
	}
	&__selfiImgContainer {
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		gap: 8;
		display: inline-flex;
	}
}
.questionnaire-list {
	display: flex;
	.questionnaire-dot {
		width: 16px;
		height: 16px;
		background: var(--color-primary-light);
		border-radius: 50px;
	}
	.questionnaire-line {
		min-height: 30px;
		width: 2px;
		margin: 0 auto;
		background: var(--color-primary-light);
		height: 100%;
	}
	.questionnaire-dot-line {
		margin-right: var(--base-margin);
	}
	.questionnaire-answer {
		font-size: var(--font-size-2);
		margin-bottom: var(--base-margin);
		a {
			margin-left: 10px;
			color: var(--color-primary-light);
			border: 1px solid var(--color-primary-light);
			padding: 1px 5px;
			border-radius: 5px;
			svg {
				margin-left: 2px;
			}
		}
	}
}
.questionnaire-status .questionnaire-list:last-child .questionnaire-line {
	width: 0;
}
.kycDetails-ssn-wrapper {
	margin-top: 12px;
}
.kycDetail-row {
	min-height: 30px;
	display: flex;
	line-height: 30px;
	gap: 24px;

	&__basicInfoContainer {
		width: 350px;
		display: flex;
		gap: 12px;
		flex: 1;
	}

	.kyc-report-label-kycValue {
		color: #1d1e21;
		font-size: 14px;
		font-family: Poppins;
		font-weight: 500;
		word-wrap: break-word;
		&__conclusion {
			display: flex;
			align-items: flex-start;
			gap: 16px;
			&__dot {
				font-size: 6px;
			}
		}
	}
	.report-label {
		min-width: 192px;
		font-size: 12px;
		color: var(--color-text-100-light);
		text-transform: capitalize;
	}
	.kyc-report-label {
		min-width: 270px;
		font-size: 12px;
		color: var(--color-text-100-light);
		text-transform: capitalize;
		flex: 1;
		&__key {
			flex: 1 1 0;
			color: #6c7489;
			font-size: 14px;
			font-family: Poppins;
			font-weight: 400;
			word-wrap: break-word;
		}
	}
	.kycDetail--ssn-bold {
		font-weight: 600 !important;
		font-size: var(--font-size-3) !important;
		line-height: 30px !important;
		color: var(--color-header-label-dark) !important;
	}
	p {
		font-size: var(--font-size-2);
		line-height: var(--line-height-4);
		color: var(--color-text-70-light);
		width: 40%;
		overflow: hidden;
		text-transform: capitalize;
		margin-left: 24px;
		margin-bottom: 0px;
		a {
			display: block;
			text-decoration: underline !important;
			text-decoration-color: blue !important;
			cursor: pointer;
			text-transform: capitalize;
			height: 24px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&__reject {
		width: 100% !important;
	}
}
.kyc-document {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	gap: var(--base-margin);
	overflow: auto;
	.kyc--document {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 10px 0;
	}
}
.kyc-doc {
	border-radius: 8px;
	object-fit: contain;
	max-width: 550px !important;
	max-height: 500px !important;
	// width: 500px !important;
	height: 250px !important;
	overflow: auto;
	transform: rotate(-90deg);
	&:hover {
		cursor: pointer;
	}
}

.kycDetails-body {
	width: 100%;
	padding: 10px 24px !important;
}

.aml-details,
.sign-agreement {
	display: flex;
	justify-content: space-between;
}

.account-detail:last-child {
	border: none;
	margin-bottom: 0;
	padding-bottom: 0;
}
.fraction-pagination {
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	color: var(--color-text-100-light);
	position: absolute;
	right: 0;
	bottom: 0;
}
.agreement-accreditation {
	display: flex;
	justify-content: space-between;
	.signAgreement,
	.accreditation {
		width: 100%;
	}
}
.fund-balance {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.totalBalance {
	font-weight: 500;
	font-size: var(--font-size-3);
	line-height: var(--line-height-4);
	text-align: right;
	color: var(--color-black);
	margin-left: calc(var(--base-margin) * 1.5);
}
.comment-box {
	display: flex;
	margin-top: 10px;
}
.replied-container {
	display: 'flex';
	flex-direction: 'column';
}
.comment-icon {
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0 var(--base-margin);
	padding: 0 var(-base-padding);
	cursor: pointer;
	.comment-dot {
		background: var(--color-text-50-light);
		width: 10px;
		height: 10px;
		border-radius: calc(var(--base-border-radius) * 4);
		margin: calc(var(--base-margin) / 8) 0;
	}
}
.aml-record {
	border-top: 2px dashed var(--color-input-border-light);
	padding-top: var(--base-padding);
	margin-top: var(--base-margin);
	.aml-record__scores {
		display: flex;
	}
	.aml-record__match-score,
	.aml-record__risk-score {
		width: 50%;
		display: flex;
		align-items: center;
	}
	.aml-record__match-value {
		color: var(--color-text-80-light);
		font-size: var(--font-size-3);
		font-weight: 500;
	}
	.aml-record__match-label {
		font-weight: 500;
		font-size: var(--font-size-2);
		color: var(--color-text-60-light);
	}
	.aml-record__match-icon {
		display: flex;
		width: 44px;
		height: 44px;
		background: rgba(139, 69, 245, 0.12);
		border-radius: calc(var(--base-border-radius) * 0.5);
		justify-content: center;
		align-items: center;
		margin-right: calc(var(--base-margin) * 0.5);
	}
	.aml-record__risk-score {
		.aml-record__match-icon {
			background: rgba(245, 175, 69, 0.12);
		}
	}
	.ri-body-scan-fill {
		font-size: var(--font-size-4);
		color: #8b45f5;
	}
	.ri-search-eye-line {
		color: var(--color-orange);
		font-size: var(--font-size-4);
	}
	.aml-record-discr-data {
		border: 0.4px solid var(--color-input-border-light);
		border-radius: calc(var(--base-border-radius) / 2);
		padding: calc(var(--base-padding) * 0.75);
		margin-top: var(--base-margin);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
	.aml-record-discr {
		font-size: var(--font-size-5);
		font-weight: 700;
		line-height: 28px;
		color: #1d1e21;
	}
	.aml-record-discr-detail {
		font-weight: 500;
		font-size: var(--font-size-3);
		line-height: 20px;
		color: var(--color-label-dark-200);
	}
	.aml-record-date-source {
		font-weight: 500;
		font-size: var(--font-size-2);
		color: var(--color-text-60-light);
		margin-top: calc(var(--base-margin) * 0.25);
		display: flex;
		align-items: center;
		.record-dot {
			width: 4px;
			height: 4px;
			background: var(--color-text-60-light);
			border-radius: calc(var(--base-border-radius) / 4);
			display: block;
			margin: 0 8px;
		}
	}
	.record-source-name {
		white-space: nowrap;
		width: calc(100% - 80px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
	a.record-source-name {
		color: #05f;
		text-decoration: underline !important;
	}
	.record-date {
		width: 80px;
	}
}
// }
.react-modal_commentBox {
	width: 65%;
	max-width: 576px;
	min-width: 420px;
	margin: auto;
	height: fit-content;
	min-height: 260px;
	max-height: 620px;
	display: flex;
	flex-direction: column;
	border-radius: var(--base-border-radius);
	.model-header {
		display: none;
	}
	.text-area__label {
		color: var(--color-text-100-light);
	}
}
.empty_data {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	min-height: 70vh;
	height: 100%;
}
.empty-data-txt {
	align-self: center;
}

.kycDetails {
	min-height: 500px;
}

.kycDetails--header {
	font-weight: 600;
	font-size: 18px;
	color: var(--color-header-label-dark);
	padding: 0 24px;
	padding-bottom: 12px;
	border-bottom: 1px solid var(--color-input-border-light);
	margin: 0 -24px;
	margin-bottom: 12px;
	align-items: baseline;
	&_detail {
		font-weight: 600;
		font-size: 18px;
		color: var(--color-header-label-dark);
		padding: 0 24px;
		padding-bottom: 12px;
		margin: 0 -24px;
		align-items: baseline;
	}
}

.kycDetails--sub-header {
	font-weight: 500;
	font-size: 16px;
	color: var(--color-header-label-dark);
}

.ActionButton--comment {
	padding: 8px;
	cursor: pointer;
	margin-right: 8px;
}

.ActionButton--comment > i {
	font-size: 24px;
	color: var(--color-text-50-light);
}

.ActionButton--comment > i:hover {
	color: var(--color-black);
}

.kycDetails--subHead {
	// margin-bottom: 4px;
	// margin-top: 16px;
	font-weight: 500;
	font-size: var(--font-size-4);
	line-height: 30px;
	color: var(--color-header-label-dark);
}

.kycDetail-reports {
	display: flex;
	justify-content: space-between;
}

.kycDetails-contain {
	background: var(--color-bg-100-light);
	border: 0.4px solid var(--color-input-border-light);
	border-radius: 16px;
	display: flex;
	gap: calc(var(--base-margin) * 1);
	flex-direction: column;
	width: 100%;
	min-height: 200px;
	min-width: 560px;
	padding: unset;
	.form-icon-container {
		display: flex;
		gap: 8px;
		align-items: center;
		.form-icon-profile-container {
			background-color: #7935de;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			&__formIcon {
				color: white;
			}
		}
		.accreditation-icon {
			background-color: #389f75 !important;
		}
		.fund-account-icon {
			background-color: purple !important;
		}
		.background-check-icon {
			background-color: #2269d3 !important;
		}
		.sign-agreement-icon {
			background-color: #2269d3 !important;
		}
	}
}
.session-info {
	background: var(--color-bg-100-light);
	border: 0.4px solid var(--color-input-border-light);
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 0px;
	min-width: 560px;
	&__header {
		width: 100%;
		height: 56px;
		padding: 6px 16px 16px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--color-input-border-light);
		&__icon-container {
			display: flex;
			align-items: center;
			i {
				font-size: 24px;
				color: var(--color-text-80-light);
			}
			span {
				font-weight: 600;
				font-size: var(--font-size-4);
				color: var(--color-text-80-light);
				margin-left: 8px;
			}
		}
	}
	&__body {
		height: calc(100% - 56px);
		width: 100%;
		display: flex;
		gap: 4px;
		&__details-container {
			width: 45%;
			height: 100%;
			padding: 16px 16px 6px;
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
		&__details {
			display: flex;
		}
		&__detailed {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin-left: 8px;
			width: 100%;
		}
		&__icon-container {
			min-width: 40px;
			min-height: 40px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				font-size: 24px;
			}
		}
		&__key {
			font-size: var(--font-size-2);
			font-weight: 500;
			color: var(--color-text-60-light);
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&__value {
			font-size: var(--font-size-3);
			font-weight: 500;
			color: var(--color-text-90-light);
			width: 84%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.date {
	font-size: var(--font-size-3);
	font-weight: 500;
	color: var(--color-text-60-light);
	display: flex;
	align-items: center;
}
.device {
	background-color: rgba(245, 175, 69, 0.12);
	i {
		color: var(--color-orange);
	}
}
.user {
	background-color: rgba(139, 69, 245, 0.12);
	i {
		color: var(--color-purple);
	}
}
.host {
	background-color: rgba(51, 184, 122, 0.12);
	i {
		color: var(--color-green);
	}
}
.browser {
	background-color: rgba(69, 139, 245, 0.12);
	i {
		color: var(--color-blue);
	}
}
.location {
	background-color: rgba(245, 69, 133, 0.12);
	i {
		color: var(--color-pink);
	}
}
.referrer {
	background-color: rgba(135, 140, 153, 0.12);
	i {
		color: var(--color-gray);
	}
}
.session-info:hover {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
}
.kycDetails-contain:hover {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
}

.kycDetails-noDataFound {
	height: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	div {
		color: var(--color-text-60-light);
		margin-top: 12px;
	}
}

.empty_data_img {
	margin-bottom: 12px;
}

.Session-show-status {
	display: flex;
	margin: 8px 0;
}

.Session-show-status-contain {
	display: flex;
	align-items: center;
	margin-right: 16px;
	div {
		text-transform: capitalize;
		font-size: 11px;
		font-weight: 500;
	}
}

.Session-show-status-circle {
	width: 16px;
	height: 16px;
	background-color: var(--color-border-90-light);
	border-radius: 50%;
	margin-right: 6px;
}

.kycDetail-swipe-label {
	font-size: 16px;
	text-transform: capitalize;
	min-width: 160px;
}

.kycDetail-swipe-value {
	font-size: 16px;
	text-transform: capitalize;
	color: var(--color-text-40-light);
}

.kycDetail-swipe-url {
	font-size: 16px;
	color: var(--color-text-40-light);
	margin-bottom: 12px;
	a {
		margin-left: 22px;
		word-break: break-all;
		padding: 2px 10px;
		background: var(--color-button-bg-light-blue);
		border-radius: calc(var(--base-border-radius) * 1.5);
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
		color: var(--color-primary-light);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.session-column {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	.session-info-container {
		display: flex;
		flex-direction: column;
	}
	.session-col-detail,
	.session-col-activity {
		width: 50%;
	}
	.activitylog {
		padding: 15px;
	}
	.activity-container {
		display: flex;
		flex-direction: column;

		.comment-send {
			position: sticky;
			top: -20px;
			background: var(--color-bg-100-light);
			z-index: 1;
			.comment-footer {
				padding: calc(var(--base-padding) * 0.75);
				border: 1px solid var(--color-input-border-light);
				border-top: none;
				border-radius: 0 0 calc(var(--base-border-radius) * 0.5)
					calc(var(--base-border-radius) * 0.5);
				.comment--footer {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
			.comment-btn {
				min-height: 32px;
			}
			.input__text-field {
				border-radius: calc(var(--base-border-radius) * 0.5)
					calc(var(--base-border-radius) * 0.5) 0 0;
			}

			.text-area-field {
				font-size: var(--font-size-3);
			}
		}
		.activity-btn-container {
			// margin-bottom: var(--base-padding);
			border-bottom: 1px solid var(--color-input-border-light);
			.activity-tab {
				color: var(--color-text-60-light);
				background: none;
				border: none;
				font-size: var(--font-size-2);
				padding: calc(var(--base-padding) * 0.75);
				font-weight: 500;
				cursor: pointer;
				&.active {
					color: var(--color-primary-light);
					border-bottom: 2px solid var(--color-primary-light);
				}
			}
		}
	}
	.comment__action-container {
		display: flex;
		flex-direction: column;
		gap: 18px;
		padding-bottom: var(--base-padding);
		padding-right: calc(var(--base-padding) * 0.25);
		padding: 16px 24px !important;

		.activity-comment {
			width: 100%;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: var(--color-text-60-light);
			display: flex;
			&:first-child {
				margin-top: 8px;
			}
			.comment-head-container {
				width: 100%;
				margin: auto;
			}
			.commentor-user-details {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
			.commentator-name {
				font-size: 12px;
				line-height: 20px;
				.commentator__name_format {
					text-transform: capitalize;
					font-weight: 500;
					color: var(--color-black);
				}
			}
			.comment-text {
				font-weight: 400;
				font-size: var(--font-size-2);
				line-height: 16px;
			}
			.comment-date {
				margin: 2px 0 6px;
				color: var(--color-text-50-light);
			}
			.commentator-image {
				width: 32px;
				height: 32px;
				border-radius: 50px;
				background: var(--color-turquois);
				color: var(--color-bg-100-light);
				font-weight: 500;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
			}
			.replied-comment-image {
				width: 32px;
				height: 32px;
				border-radius: 50px;
				background: var(--color-orange);
				color: var(--color-bg-100-light);
				font-weight: 500;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
			}
			.comment-message {
				word-break: break-all;
				font-size: var(--font-size-2);
				color: var(--color-text-80-light);
			}
			.comment-document-wrapper {
				margin-top: 8px;
				border: 1px solid var(--color-input-border-light);
				border-radius: 8px;
				padding: 8px;
				.comment-document {
					padding: 8px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 8px;
					cursor: pointer;
					border-bottom: 1px solid var(--color-input-border-light);
					&:last-child {
						border-bottom: unset;
					}
					&:hover {
						background-color: #f3f6fd;
					}
					.comment-document__disc-wrapper {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
					.comment-document__password-wrapper {
						display: flex;
						align-items: center;
						gap: 8px;
					}
					.comment-document__left {
						display: grid;
						grid-template-columns: 40px max-content;
						column-gap: 8px;
						row-gap: 4px;
						&__image-wrapper {
							grid-row: 1 / span 2;
							width: 40px;
							height: 40px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 8px;
							border-right: 1px solid var(--color-input-border-light);
						}
						&__heading {
							font-weight: 500;
							font-size: var(--font-size-3);
							line-height: 20px;
							color: var(--color-text-80-light);
							max-width: 320px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						&__sub-heading {
							font-weight: 400;
							font-size: var(--font-size-2);
							line-height: 16px;
							color: var(--color-text-70-light);
							text-transform: capitalize;
						}
					}
					.comment-document__right {
						font-weight: 500;
						font-size: var(--font-size-6);
						line-height: 20px;
						color: var(--color-text-50-light);
						cursor: pointer;
						padding: 8px;
					}
					&__password-text {
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: var(--color-text-80-light);
					}
					&__password {
						width: max-content;
						border: unset;
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: var(--color-text-80-light);
					}
					&__password-btn {
						background: rgba(47, 50, 61, 0.06);
						border-radius: 32px;
						border: none;
						padding: 4px 8px;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: var(--color-text-80-light);
						border: 1px solid transparent;
						&:hover {
							border: 1px solid rgba(0, 0, 0, 0.18);
						}
					}
				}
			}
			.email-sent-badge {
				white-space: nowrap;
				display: flex;
				flex-direction: row;
				font-size: 10px;
				align-items: flex-start;
				padding: calc(var(--base-padding) * 0.25)
					calc(var(--base-padding) * 0.5);
				color: var(--color-text-70-light);
				background: rgba(54, 58, 69, 0.06);
				border-radius: 18px;
			}
			.email-cc-count {
				border: 1px solid var(--color-input-border-light);
				border-radius: calc(var(--base-border-radius) / 4);
				background: #ffff;
				margin-left: auto;
				margin-right: calc(var(--base-margin) * 0.5);
				color: var(--color-text-60-light);
				font-weight: 500;
				font-size: var(--font-size-2);
				line-height: var(--line-height-3);
				position: relative;
			}
			.cc-count {
				position: absolute;
				font-size: 8px;
				width: 14px;
				height: 14px;
				text-align: center;
				background: var(--color-primary-light);
				border-radius: calc(var(--base-border-radius) * 1.5);
				color: var(--color-bg-100-light);
				display: flex;
				right: -4px;
				top: -8px;
				align-items: center;
				justify-content: center;
				padding-top: 2px;
			}
			table,
			tr,
			th {
				width: 100%;
			}
			th {
				inset: 0;
				padding-top: -9px;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
	.activity-sticky {
		position: sticky;
		top: 116px;
		z-index: 1;
		.comment__action-container {
			height: 64.5vh;
			padding-bottom: var(--base-padding) 0;
			padding-right: calc(var(--base-padding) * 0.5);
			overflow-y: scroll;
		}
		img {
			max-width: 100px;
		}
	}
}

.email-cc-toolTip {
	z-index: 1000;
	color: #fff;
	background: #000;
	text-align: justify !important;
}

.SessionTable-invite-disable {
	.Review {
		color: var(--color-text-60-light);
		cursor: not-allowed;
	}
}

.Comments--modal {
	.Modal__wrapper {
		width: 400px;
	}
}

.CommentFooter--right {
	display: flex;
	justify-content: flex-end;
	.button__filled {
		margin-left: 16px;
	}
}

.loader-container {
	width: 100%;
	height: calc(100vh - 120px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.kycDetails-img-wrapper {
	overflow: hidden;
	width: 100%;
	height: 100%;
}
.email--checkbox {
	display: flex;
	gap: 8px;
	.add-cc {
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--base-border-radius) / 4);
		background: none;
		margin-left: calc(var(--base-margin) * 1.5);
		color: var(--color-text-60-light);
		font-weight: 500;
		font-size: var(--font-size-2);
		line-height: var(--line-height-3);
		position: relative;
		padding: 0 4px;
		&:hover {
			cursor: pointer;
			border: 1px solid var(--color-primary-light);
		}
	}
	.cc-count {
		position: absolute;
		font-size: 8px;
		width: 14px;
		height: 14px;
		text-align: center;
		background: var(--color-primary-light);
		border-radius: calc(var(--base-border-radius) * 1.5);
		color: var(--color-bg-100-light);
		display: flex;
		right: -4px;
		top: -8px;
		align-items: center;
		justify-content: center;
		padding-top: 2px;
	}
	.email--checkbox-input {
		margin: 0 8px 0 5px;
		height: 16px;
		width: 16px;
		cursor: pointer;
	}
	.email--checkbox-label {
		align-items: center;
		display: flex;
		cursor: pointer;
	}
	.lable-text {
		font-size: 12px;
		font-weight: 500;
	}
}

@media only screen and (max-width: 1220px) {
	.session-column {
		flex-direction: column;
	}
	.session-column .session-col-detail,
	.session-column .session-col-activity {
		width: 100%;
	}
	.session-column .activity-sticky .comment__action-container {
		height: 40vh;
	}
}

.comment-footer {
	.email-cc {
		display: flex;
		align-items: center;
		position: relative;
		margin-top: var(--base-margin);
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--base-border-radius) / 2);
		padding: calc(var(--base-padding) / 2);
	}

	.email-cc-to {
		font-weight: 500;
		font-size: var(--font-size-2);
		color: var(--color-label-dark);
		padding-right: 8px;
	}
	.emaill-cc-add {
		background: rgba(60, 101, 214, 0.06);
		border-radius: calc(var(--base-border-radius) * 2);
		color: var(--color-primary-light);
		border: none;
		font-weight: 500;
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
		padding: calc(var(--base-padding) / 4) calc(var(--base-padding) * 3 / 4);

		&:hover {
			border: 1px solid var(--color-primary-light);
			cursor: pointer;
		}
	}
	.ReactTags__tags {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
	}

	.ReactTags__clearAll {
		cursor: pointer;
		padding: 10px;
		margin: 10px;
		background: #f88d8d;
		color: #fff;
		border: none;
	}

	/* Styles for the input */
	.ReactTags__tagInput {
		border-radius: 2px;
		display: flex;
	}
	.ReactTags__tagInput input.ReactTags__tagInputField,
	.ReactTags__tagInput input.ReactTags__tagInputField:focus {
		width: 100%;
		font-size: var(--font-size-2);
		border: none;
		padding: 8px;
		border-radius: 4px;
		border: 0.5px solid transparent;
	}

	/* Styles for selected tags */
	.ReactTags__selected {
		display: flex;
		flex-wrap: wrap;
		overflow: auto;
		max-height: 120px;
	}
	::-webkit-scrollbar {
		width: 1px;
	}
	.ReactTags__selected span.ReactTags__tag {
		display: flex;
		margin: calc(var(--base-margin) / 4) calc(var(--base-margin) / 4);
		background: rgba(151, 151, 151, 0.12);
		border-radius: calc(var(--base-border-radius) * 2);
		color: var(--color-text-70-light);
		font-weight: 500;
		font-size: var(--font-size-2);
		line-height: 16px;
		padding: calc(var(--base-padding) / 4) calc(var(--base-padding) / 4)
			calc(var(--base-padding) / 4) calc(var(--base-padding) * 3 / 4);
	}

	/* Styles for suggestions */
	.ReactTags__suggestions {
		position: absolute;
		top: 50px;
	}
	.ReactTags__suggestions ul {
		list-style-type: none;
		padding-inline-start: 0;
		box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
		background: var(--color-bg-100-light);
		width: 100%;
		padding: calc(var(--base-padding) / 2) 0;
	}
	.ReactTags__suggestions li {
		border-bottom: 1px solid var(--color-input-border-light);
		padding: calc(var(--base-padding) / 2) calc(var(--base-padding) * 3 / 4);
		margin: 0;
		color: var(--color-text-70-light);
		font-weight: 500;
		font-size: var(--font-size-2);
	}
	.ReactTags__suggestions li mark {
		background: none;
		color: var(--color-text-70-light);
		font-weight: 500;
		font-size: var(--font-size-2);
		margin: 0;
		padding: 0;
	}
	.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
		background: var(--color-input-background);
		cursor: pointer;
	}

	.ReactTags__remove {
		border: none;
		cursor: pointer;
		background: none;
		color: var(--color-text-70-light);
		font-size: var(--font-size-4);
	}
}

.apple-id {
	padding: 6px 0px;
	width: 100%;
	display: flex;
	&_info {
		width: 46%;
		padding-left: 16px;
	}
}

.avg-confidence-progress {
	min-width: 120px;
	display: flex;
	align-items: center;
	gap: 12px;
	margin-left: 16px;
}

.kycDetails--header--toggleContainer {
	display: flex;
	align-items: center;
	gap: 16px;
	&_togglebtn {
		display: flex;
		align-items: center;
		gap: 16px;
	}
}

.kycDetails--header--export-pdf {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: calc(var(--base-margin) * 1.5);
	&_button {
		padding-right: 24px;
	}
}

.fundinvestment_communicators_title {
	font-size: var(--font-size-3);
	color: var(--color-header-label-dark);
	font-weight: bold;
}
.fundInvestment-header {
	font-size: var(--font-size-2) !important;
	font-weight: 500;
	margin-bottom: calc(var(--base-margin) * 0.25) !important;
}

.fundinvestment_communicators_devider {
	border-bottom: 1px solid var(--color-input-border-light);
	margin: 4px 0px;
}

.kyc--Details--header--sticky {
	padding: 0 24px;
	border-bottom: 1px solid var(--color-input-border-light);
	margin: 0 -24px;
	margin-bottom: 20px;
	align-items: baseline;
	position: sticky;
	top: 0;
	z-index: 2;
	background: white;
}
.uploaded {
	font-weight: 600;
}

.session-header-action-btns {
	margin: calc(var(--base-padding) * 0.75) calc(var(--base-padding) * 1.5) 0px;
	border-bottom: 1px solid var(--color-gray-dark-100);
	padding-bottom: 12px;
	display: flex;
	align-items: center;
	gap: 24px;
	justify-content: space-between;
	flex-flow: wrap;
	.assign-fund-btn {
		border: 1px solid #3c65d6;
		border-radius: 8px;
		color: #3c65d6;
		min-height: unset !important;
		padding: 12px;
	}
	.session-right {
		display: flex;
		flex: 1;
		gap: 16px;
		justify-content: flex-start;
	}
	.arc-btn {
		background: rgba(245, 83, 83, 0.06);
		border-radius: 8px;
		cursor: pointer;
		padding: 6px 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
		gap: 4px;
		height: 48px;
		min-width: 160px;
	}
}

.session-checkbox {
	transform: 'scale(1.2)';
	margin-left: 5px;
	cursor: pointer;
	width: 16px;
	height: 16px;
	accent-color: var(--color-primary-light);
}
.email__contact {
	overflow: hidden;
	text-overflow: ellipsis;
}
.sessions-row-checkbox {
	display: flex;
	align-items: center;
}
.text-ellipsis {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.align__back_icon {
	display: flex;
	align-items: center;
	gap: 15px;
	.arrow__left_icon_main {
		margin-top: 6px;
		:hover {
			cursor: pointer;
		}
	}
}

.kycUserName {
	display: flex;
	align-items: center;
	gap: 10px;
	.arrow__left_icon_main {
		margin-top: 6px;
		:hover {
			cursor: pointer;
		}
	}
}
.user__name_space {
	padding: 14px 0;
}

.kyc-label-wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}
.kyc-label-danger {
	color: #c43131;
	font-size: 16px;
}
.kyc-label-success {
	color: #2e845c;
	font-size: 16px;
}
.avg-risk-progress {
	min-width: 190px;
	display: flex;
	align-items: center;
	gap: 8px;
	margin-left: 24px;
}
.risk-label {
	color: var(--color-text-130-light);
	font-weight: 500;
	font-size: 14px;
}
.resend-loader {
	width: 58px;
}

.gap-col-16 {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.gap-row-16 {
	display: flex;
	gap: 16px;
}

.age-condition {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	gap: 20px;
	background-color: #fff;
	border: 0.4px solid var(--color-input-border-light);
	border-radius: 5px;
	align-items: center;
	justify-content: flex-start;
	padding: 8px;
	.session-kyc-age {
		text-transform: capitalize;
		padding: 10px;
		background-color: grey;
		border-radius: 4px;
		background: var(--color-bg-light-90, #f4f4f6);
		text-align: center;
	}
}
.age-condition:hover {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
}
.form-one {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 0px;
}

.session-linear-flow-view {
	font-weight: 600;
	font-size: 18px;
	color: var(--color-header-label-dark);
	padding: 16px 0px;
}

.flow-complex-tab {
	display: flex;
	.flow-tab_lists {
		padding: 24px 16px;
	}
}

.conditional-session-tab {
	padding-bottom: 0px;
	margin-top: 0px;
}

.complex-flow-info {
	color: #7c8398;
	font-size: 16px;
	cursor: pointer;
	&:hover {
		color: var(--color-black);
	}
}
.loading_space {
	padding-block: 17px 14px;
}

.kycDetails-info {
	&__documents {
		color: var(--color-primary-light, #0051cc);
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
	}
	&__documents {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		display: inline-flex;
		&_inner {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			&_title {
				color: #0051cc;
				font-size: 16px;
				font-family: Poppins;
				font-weight: 600;
				word-wrap: break-word;
			}
		}
	}
	&__lines {
		flex: 1 1 0;
		height: 1px;
		background: #dfe3ec;
	}
	img {
		width: 100%;
	}
	&__documentView {
		&_inner {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			gap: 30px;
			padding: 12px 0;
			&_img {
				width: 320px;
				height: 200px;
				img {
					height: inherit;
					width: inherit;
					object-fit: cover;
					border-radius: 8px;
					cursor: pointer;
					&:hover {
						box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
					}
				}
			}
		}
	}
	.personal-details-container {
		&__selfiImgContainer {
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			gap: 8px;
			display: inline-flex;
		}
		&__selfiImg {
			width: 144px;
			height: 144px;
			position: relative;
			background: #e6e6e6;
			border-radius: 4px;
		}
		&__img {
			width: 100%;
			height: 100%;
			display: block;
		}
		&__matchPercentage {
			text-align: center;
			color: black;
			font-size: 16px;
			font-family: Poppins;
			font-weight: 600;
			line-height: 24px;
			word-wrap: break-word;
			padding: 4px;
		}
	}
}
.kyc-doc {
	border-radius: 8px;
	object-fit: contain;
	max-width: 550px !important;
	max-height: 500px !important;
	height: 400px !important;
	overflow: auto;
}

.kyc-document {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 10px;
	gap: var(--base-margin);
	overflow: auto;
}

.kycDetail-row {
	min-height: 30px;
	display: flex;
	line-height: 30px;
	.report-label {
		min-width: 192px;
		font-size: 12px;
		color: var(--color-text-100-light);
		text-transform: capitalize;
	}
	p {
		font-size: var(--font-size-2);
		line-height: var(--line-height-4);
		color: var(--color-text-70-light);
		width: 40%;
		overflow: hidden;
		text-transform: capitalize;
		margin-left: 24px;
		margin-bottom: 0px;
	}
	.percentage__confidence {
		width: 10%;
	}
}

.kyc-user-profile {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	padding: 12px 0;
	&__profile-image {
		width: 200px;
		height: 180px;
		object-fit: cover;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__placeholder-image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	&__match-percentage {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		text-align: center;
	}
}
.Sessions-Details--header{
	padding-bottom: unset !important;
}